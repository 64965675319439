<template>
  <main>
    <nav class="navbar navbar-expand-lg navbar-light bg-light" style="font-family: Inter Variable;">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'Estudiante' }" exact-active-class="router-link-active">
                Perfil
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'Informacion de Usuario' }" exact-active-class="router-link-active">
                Ver Información
              </router-link>
            </li>
            <li class="nav-item" v-show="this.statusUser === true">
              <router-link class="nav-link" :to="{ name: 'Vacantes' }" exact-active-class="router-link-active">
                Vacantes
              </router-link>
            </li>
            <li class="nav-item" v-show="this.statusUser === true">
              <router-link class="nav-link" :to="{ name: 'Postulaciones' }" exact-active-class="router-link-active">
                Postulaciones
              </router-link>
            </li>
            <li class="nav-item" v-show="this.statusUser === true">
              <router-link class="nav-link" :to="{ name: 'Recibos' }"
                exact-active-class="router-link-active">
                Recibos
              </router-link>
            </li>
            <li class="nav-item dropdown" v-show="statusUser === true" @mouseleave="showCodes = false" v-if="this.statusUser">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Verifícate
              </a>
              <ul class="dropdown-menu" ref="verificationDropdown">
                <li>
                  <router-link class="dropdown-item" :to="{ name: 'Verificacion de Usuario' }" exact-active-class="router-link-active">
                    Mi Verificación
                  </router-link>
                </li>
                <li v-for="(ver, i) in verifiedData" :key="i + 1">
                  <a class="dropdown-item" href="#" @click.stop="toggleCodes" v-if="ver.verificationStatus" style="cursor: pointer;">
                    Generar Código
                  </a>
                </li>
                <li v-if="showCodes" class="dropdown-item" style="position: relative;">
                  <div style="position: absolute; left: 101%; top: 0; transform: translateY(-30px); z-index: 1000; background-color: #03337A; color: white; padding: 10px; border-radius: 5px;">
                    <div v-if="isLoadingCodes" class="text-center">
                      <small>Cargando...</small>
                    </div>
                    <div v-else>
                      <div 
                        v-for="promo in promoCodes" 
                        :key="promo._id" 
                        class="text-center" 
                        @click="copyCode(promo.code)"
                        style="cursor: pointer; color: white;">
                        <small>{{ promo.code }}</small>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
          <ul class="navbar-nav mb-2 mb-lg-0">
            <li><router-link class="dropdown-item" to="/" @click="logout()">Cerrar Sesión</router-link></li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- Carga de Componente -->

    <body class="container">
      <router-view />
    </body>
  </main>
</template>

<script>
import { UserService } from "@/services";
export default {
  data: () => {
    return {
      statusUser: Boolean,
      dataUser: {},
      verifiedData: {},
      userID: "",
      showCodes: false,
      promoCodes: [],
      nameUser: "",
      imageName: "",
      imageUrl: "/images/profile.jpg",
      imageFile: "",
      response: {
        loading: false,
      },
      urlImage: "#f5782d",
      socialMedia: [
        {
          title: "Facebook",
          icon: "fab fa-facebook",
          active: true,
        },
        {
          title: "Instagram",
          icon: "fab fa-instagram",
          active: false,
        },
        {
          title: "LinkedIn",
          icon: "fab fa-linkedin-in",
          active: false,
        },
        {
          title: "Whatsapp",
          icon: "fab fa-whatsapp",
          active: false,
        },
        {
          title: "Telegram",
          icon: "fab fa-telegram",
          active: false,
        },
      ],
    };
  },
  mounted() {
    this.dataUser = JSON.parse(localStorage.getItem("user") || null);
    this.dataUser._id = this.userID;
    this.statusUser = this.dataUser.status;
    console.log(this.dataUser);
    const dropdownElement = this.$refs.verificationDropdown;
    if (dropdownElement) {
      dropdownElement.addEventListener('hidden.bs.dropdown', () => {
        this.showCodes = false;
      });
    }
  },
  beforeMount(){
    this.extractionVerification();
    this.loadPromoCodes();
  },  
  methods: {
    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      this.removeItem("token");
      window.location.href = "/";
    },
    removeItem(sKey, sPath, sDomain) {
      document.cookie =
        encodeURIComponent(sKey) +
        "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" +
        (sDomain ? "; domain=" + sDomain : "") +
        (sPath ? "; path=" + sPath : "");
    },
    async savedData(file) {
      try {
        this.response.loading = true;
        var resp = await UserService.savedImage({ file: file });
        var { data } = resp.data;
        var user = JSON.parse(localStorage.getItem("user") || null);
        user.photo = data;
        this.dataUser = user;
        this.response.loading = false;
        localStorage.setItem("user", JSON.stringify(user));
      } catch ($e) {
        this.response.loading = false;
      }
    },
    toggleCodes() {
      this.showCodes = !this.showCodes;
      if (this.showCodes && this.promoCodes.length === 0) {
        this.isLoadingCodes = true;
        this.loadPromoCodes();
      }
    },
    async loadPromoCodes() {
      try {
        const response = await UserService.getAllPromos();
        const currentDate = new Date();
        this.promoCodes = response.data.filter(promo => {
          return new Date(promo.expirationDate) > currentDate;
        });
      } catch (error) {
        console.error('Error al cargar los códigos promocionales:', error);
      } finally {
        this.isLoadingCodes = false;
      }
    },
    async copyCode(code) {
      try {
        await navigator.clipboard.writeText(code);
        alert('Código copiado exitosamente: ' + code);
        this.showCodes = false; 
      } catch (error) {
        console.error('Error al copiar el código:', error);
      }
    },
    async extractionVerification() {
      await UserService.getVerifiedData().then((response) => {
        const studentVerified = response.data;
        this.verifiedData = studentVerified;
      });
    },
  },
};
</script>
<style lang="scss">
@import "../../styles/profile.scss";
</style>
